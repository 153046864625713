import React, { useState } from "react";
import styled from "styled-components";
// import IndentPage from "../../pages/Indent/View/IndentPage";
// import IndentBooking from "../../pages/Indent/IndentBooking";
import { useNavigate } from "react-router-dom";
// import Dashboard from "../../pages/Dashboard/Dashboard";
// import ReceiptIntimation from "../../pages/ReceiptIntimation/ReceiptIntimation";
import { FaHome } from "react-icons/fa";
import { FaReceipt } from "react-icons/fa";
import { MdPayments } from "react-icons/md";
import { MdEditDocument } from "react-icons/md";

const SidebarContainer = styled.div`
  height: 100vh;
  width: 16vw;
  border-right: 1px solid #787676;
`;

const SidebarHead = styled.div`
  height: 10vh;
  border-bottom: 1px solid #ccc;
  color: #5783db;
  font-weight: 800;
  cursor: default;
  font-size: 36px;
  letter-spacing: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SidebarTab = styled.div`
  display: flex;
  background-color: ${(props) => (props.selected ? "#5783db" : "")};
  padding: 10px 10px;
  font-size: 16px;
  align-items: center;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  justify-content: center;
`;

const SidebarTabText = styled.div`
  background-color: ${(props) => (props.selected ? "#5783db" : "")};
  color: ${(props) => (props.selected ? "white" : "black")};
  transition: all 0.2s ease-in-out;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  width: 200px;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
`;

const routes = [
  {
    path: "dashboard",
    element: "Dashboard",
    icon: <FaHome />,
  },
  {
    path: "indentbooking",
    element: "Indent Booking",
    icon: <MdPayments />,
  },
  {
    path: "receiptintimation",
    element: "Payment Intimation",
    icon: <FaReceipt />,
  },
  {
    path: "documentapproval",
    element: "Document Approval",
    icon: <MdEditDocument />,
    auType: localStorage.getItem('au_typ')
  },
];

const Sidebar = () => {
  const Navigate = useNavigate();
  const [selected, setSelected] = useState("");
  const [textHover, setTextHover] = useState("none");

  const navigateHandler = (path) => {
    setSelected(path);
    Navigate(`/${path}`);
  };

  const mouseHoverHandler = (path) => {
    setTextHover(path);
  };

  return (
    <div>
      <SidebarContainer>
        <SidebarHead>AIS</SidebarHead>
        {routes.map((route) => {
          return (
            <SidebarTab
              onMouseOver={() => mouseHoverHandler(route.path)}
              selected={selected === route.path}
              hover={textHover === route.path}
              onMouseOut={() => setTextHover("none")}
              key={route.path}
              hidden={route.auType !== "A"}
              onClick={() => navigateHandler(route.path)}
            >
              <SidebarTabText
                hover={textHover === route.path}
                selected={selected === route.path}
              >
                <p style={{ marginTop: "3px", fontSize: "20px" }}>
                  {route.icon}
                </p>
                <p style={{ width: "160px", whiteSpace: "nowrap" }}>
                  {route.element}
                </p>
              </SidebarTabText>
            </SidebarTab>
          );
        })}
        {/* <SidebarTab onClick={() => Navigate("/dashboard")}>
          <SidebarTabText>
            <p>Home</p>
          </SidebarTabText>
        </SidebarTab>
        <SidebarTab onClick={() => Navigate("/indentpage")}>Indent</SidebarTab>
        <SidebarTab onClick={() => Navigate("/receiptintimation")}>
          Recipt Intimation
        </SidebarTab> */}
        {/* <SidebarTab>Reset Password</SidebarTab>
        <SidebarTab>Salesman</SidebarTab>
        <SidebarTab>Reference Parties</SidebarTab>
        <SidebarTab>Price List</SidebarTab>
        <SidebarTab>Approvals</SidebarTab>
        <SidebarTab>Change Password</SidebarTab> */}
      </SidebarContainer>
    </div>
  );
};

export default Sidebar;
