import React, { useEffect, useState } from 'react';
import axios from "axios";
import { fixDateHandler } from "../ReceiptIntimation/ReceiptIntimation";
import { toast } from "react-toastify";
import Checkbox from '@mui/material/Checkbox';

const ReceiptApproval = () => {
    const [receiptData, setReceiptData] = useState([]);
    const [checked, setChecked] = useState("")

    useEffect(() => {
        fetchReceiptData()
    }, [])

    const fetchReceiptData = async () => {
        try {
            let result = await axios.get(`https://aisapi.solzon.in/api/ReceiptIntimation/PendingApproval?p_aup_cd=${localStorage.getItem("aup_cd")}`)
            console.log(result.data)
            setReceiptData(result.data.data)
        } catch (err) {
            console.log(err)
        }
    }

    const approveReceipt = async (number) => {
        setChecked(number)
        try {
            let result = await axios.put(`https://aisapi.solzon.in/api/ReceiptIntimation/ApprovedRejectReceiptIntimation?p_ri_id=${number}&p_sts=0&p_auth_aup_cd=${localStorage.getItem("aup_cd")}`)
            toast.success(result.data.data)
            console.log(result.data)
            fetchReceiptData()
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className="tableContainer">
            <table className="table">
                <thead>
                    <tr>
                        <th>Number</th>
                        <th>Date</th>
                        <th>Customer Name</th>
                        <th>Company</th>
                        <th>Deposited In</th>
                        <th>Amount</th>
                        <th>Approve</th>
                    </tr>
                </thead>
                <tbody>
                    {receiptData?.map((item) => {
                        return (
                            <tr key={item.ri_id}>
                                <td>{item.ri_id}</td>
                                <td>{fixDateHandler(item.ri_dt)}</td>
                                <td>{item.ac_nm}</td>
                                <td>{item.co_nm}</td>
                                <td>{item.hb_nm}</td>
                                <td>{item.chq_amt}</td>
                                <td><Checkbox checked={checked === item.ri_id} onClick={() => approveReceipt(item.ri_id, item.sts)} /></td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default ReceiptApproval;