import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import styles from "./Documentapproval.module.css";
import IndentApproval from "./IndentApprovalComponent";
import ReceiptApproval from "./ReceiptApprovalComponent"

const MainComponent = () => {
    const [currentSection, setCurrentSection] = useState(false);

    return (
        <div>
            <ToastContainer />
            <div className={styles.documentMain}>
                <h3 style={{ margin: "2% 2vw" }}>Document Approval</h3>
                <div className={styles.documentSubOne}>
                    <button style={!currentSection ? { backgroundColor: "#0055ff" } : null} className={styles.buttonMain} onClick={() => setCurrentSection(false)}>Indent Booking</button>
                    <button style={currentSection ? { backgroundColor: "#0055ff" } : null} className={styles.buttonMain} onClick={() => setCurrentSection(true)}>Payment Intimation</button>
                </div>
            </div>
            {currentSection ? (
                <ReceiptApproval />
            ) : (
                <IndentApproval />
            )}
        </div>
    )

}

export default MainComponent;