import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import select from '@mui/material/select';
// import option from '@mui/material/option';
// import label from '@mui/material/label';
// import input from '@mui/material/input'

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
// import EditIcon from '@mui/icons-material/Edit';
import "./IndentBookingStyle.css";
import styles from "./indentbooking.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLoading } from "../../Components/Loader/LoadingContext";
import Loader from "../../Components/Loader/LoaderComponent";
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

const MainComponent = () => {
  const [selectedOption, setselectedOption] = useState("");
  const [item, setItem] = useState("");
  const [width, setwidth] = useState("");
  const [pattern, setPattern] = useState(null);
  const [lengthName, setLengthName] = useState(null);
  const [patternName, setPatternName] = useState(null);
  const [length, setLength] = useState(null);
  const [quantity, setQyantity] = useState("");
  const [uomCd, setUomCd] = useState("");
  const [remark, setRemark] = useState("");
  const [orderDetails, setOrderDetails] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [patternList, setPatternList] = useState([{}]);
  const [lengthList, setLengthList] = useState([]);
  const [itemListMain, setItemListMain] = useState([]);
  const [disabledPattern, setDisabledPattern] = useState(true);
  const { isLoading, setIsLoading } = useLoading();
  
  // const [tempPkQty, setTempPkgQty] = useState('');

  const handleChange = (event,value) => {
    if(!value) return setselectedOption("")
    setselectedOption(value.ac_cd);
  };

  const handleChangePattern = (event,value) => {
    if(!value) return setPattern("")
      console.log(value)
      setPattern(value.dw_id);
      setPatternName({dw_nm:value.dw_nm})
  };

  const handleChangeLength = (event,value) => {
    if(!value) return setLength("")
      setLength(value.dl_id);
      setLengthName({dl_nm:value.dl_nm})
  };

  useEffect(() => {
    setIsLoading(true)
    axios
      .get(
        `https://aisapi.solzon.in/api/Customers/List?aup_cd=${localStorage.getItem('aup_cd')}&au_type=${localStorage.getItem('au_typ')}`
      )
      .then((response) => {
        setCustomerList(response.data.data);
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`https://aisapi.solzon.in/api/Indent/MerchantNumbers`)
      .then((response) => {
        setItemListMain(response.data?.data);
        let itemList = response.data?.data.map((obj) => obj.mn_nm);
        setItemList(itemList);
        setIsLoading(false)
        // setData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
      // eslint-disable-next-line
  }, []);

  const handleAdd = () => {
    if(!item || !quantity) return toast.error('Fields are missing.')
      let temp = {
      item: item,
      width: width,
      pattern: pattern,
      length: length,
      patternName: patternName?.dw_nm,
      lengthName: lengthName?.dl_nm,
      quantity: quantity,
      uomcd: uomCd,
      remark: remark,
      originalQuantity: quantity,
    };
    let itemMain = itemListMain.find((obj) => obj["mn_nm"] === item);
    if(itemMain?.hz_dsg === 1 && (!pattern || !length)) return toast.error('Fields are missing.')
    setDisabledPattern(true)
    temp['itemCode'] = itemMain.mn_cd
    if(itemMain?.uom_cd === 'MTRS'){
      let tempOne = Math.round(quantity*itemMain.pkg_qty)
      temp.quantity = quantity
      temp['originalQuantity'] = tempOne
    }
    const newArray = [...orderDetails, temp];
    console.log(newArray)
    setOrderDetails(newArray);
    setItem("");
    setwidth("");
    setQyantity("");
    setPattern(null);
    setLength(null);
    setPatternName(null);
    setLengthName(null);
    setPatternList([])
    setLengthList([])
    setRemark("");
  };

  const handleChangeItem = (event,value) => {
    setItem(value);
    let item = itemListMain.find((obj) => obj["mn_nm"] === value);
    let uom_cd = item?.uom_cd === "MTRS" ? "Bales" : item?.uom_cd
    setUomCd(uom_cd)
    // setTempPkgQty(item.pkg_qty)
    if (item?.hz_dsg === 1) {
      setDisabledPattern(false);
      axios
        .get(
          `https://aisapi.solzon.in/api/Indent/ListOfItemsDesignWidth?p_ms_id=${item?.ms_id}`
        )
        .then((response) => {
          setPatternList(response.data?.data);
          axios
            .get(
              `https://aisapi.solzon.in/api/Indent/ListOfDesignLength?p_ms_id=${item?.ms_id}`
            )
            .then((response) => {
              setLengthList(response.data?.data);
              console.log(response.data.data)
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }else{
      setDisabledPattern(true)
      setLengthList([])
      setPatternList([])
    }
  };
  
  const removeItem = (objectIdToRemove) => {
    let orderDetailsTemp = [...orderDetails]
    let arrayOfObjects = orderDetailsTemp.filter(obj => obj.item !== objectIdToRemove);
    setOrderDetails(arrayOfObjects)
  }
  
  // const editItem = (objectId) => {

  // }

  const handleSubmit = async () => {
    if(orderDetails.length === 0) return toast.error('Atleast one item is required.')
    if(!selectedOption) return toast.error('Customer name is mandatory.')
    const newArray = [];
    for (let i = 1; i <= orderDetails.length; i++) {
      newArray.push(i);
    }
    let p_ii_id_s = newArray
    let p_mn_cd_s = orderDetails.map(item => item.itemCode)
    let p_dw_id_s = orderDetails.map(item => item.pattern)
    let p_dl_id_s = orderDetails.map(item => item.length)
    let p_qty_req_s = orderDetails.map(item => item.quantity)
    let p_rmrk_s = orderDetails.map(item => item.remark)
    let data = {
      "p_ac_cd": selectedOption,
      "p_ii_id_s": `${formateMaker(p_ii_id_s)}`,
      "p_mn_cd_s": `${formateMaker(p_mn_cd_s)}|`,
      "p_dw_id_s": `${formateMaker(p_dw_id_s)}|`,
      "p_dl_id_s": `${formateMaker(p_dl_id_s)}|`,
      "p_qty_req_s": `${formateMaker(p_qty_req_s)}|`,
      "p_rmrk_s": `${formateMaker(p_rmrk_s)}|`,
      "p_aup_cd": localStorage.getItem("aup_cd")
    }
    try{
      let result = await axios.post('https://aisapi.solzon.in/api/Indent/AddIndent',data)
      toast.success(result.data.data)
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }catch(err){
      console.log(err.response)
      toast.error('something went wrong/')
    }
  }

  const formateMaker = (arr) => {
    let tempArr = [...arr]
    tempArr = tempArr.filter(element => element !== undefined);
    tempArr = tempArr.join(',')
    tempArr = tempArr.replaceAll(',','|')
    return tempArr
  }

  const Navigate = useNavigate();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div className={styles.mainContainer}>
      <div className={styles.viewButton}>
        <h1>Indent Booking</h1>
        <button onClick={() => Navigate("/indentpage")}>View</button>
      </div>
        <div className={styles.formTable}>
          <div className={styles.detailsFormSelect}>
            <div className={styles.formSelect}>
              {/* <label id="dropdown-label">Customer: </label>
              <select labelId="dropdown-label" value={selectedOption} onChange={handleChange}>
                <option value="">None</option>
                {customerList.map((item) => (
                  <option value={item.ac_cd} key={item.ac_cd}>
                    {item.ac_nm}
                  </option>
                ))}
              </select> */}
              <ThemeProvider theme={theme}>
                <Autocomplete
                  options={customerList}
                  getOptionLabel={(option) => option.ac_nm}
                  size="small"
                  renderInput={(params) => 
                    <TextField 
                      {...params} 
                      label="Customer Name" 
                      variant="outlined" 
                    />
                  }
                  style={{ width: 310 }}
                  onChange={handleChange}
                />
              </ThemeProvider>
            </div>
            {/* <div className={styles.formSelect}>
              <TextField
                  label="Remark"
                  variant="outlined"
                  style={{ width: 270 }}
                  size="small"
                />
            </div> */}
          </div>
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Pattern</th>
                  <th>Length (size)</th>
                  <th>Quantity</th>
                  <th>UOM</th>
                  <th>Remarks</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {" "}
                    {/* <select
                      labelId="item-label"
                      id="dropdown"
                      value={item}
                      onChange={handleChangeItem}
                      className="select-table"
                      style={{width:'80%'}}
                    >
                      <option value="">None</option>
                      {itemList.map((item) => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    </select>0 */}
                    <ThemeProvider theme={theme}>
                      <Autocomplete
                        options={itemList}
                        getOptionLabel={(option) => option}
                        size="small"
                        value={item}
                        renderInput={(params) => 
                          <TextField 
                            {...params} 
                            variant="outlined" 
                          />
                        }
                        style={{ width: 250 }}
                        onChange={handleChangeItem}
                      />
                    </ThemeProvider>
                  </td>
                  <td>
                    {" "}
                    {/* <select
                      labelId="pattern-label"
                      id="dropdown"
                      value={pattern}
                      disabled={disabledPattern}
                      onChange={(e) => setPattern(e.target.value)}
                      className="select-table"
                    >
                      <option value="">None</option>
                      {patternList.map((item) => (
                        <option value={item.dw_id} key={item.dw_id}>
                          {item.dw_nm}
                        </option>
                      ))}
                    </select> */}
                    <ThemeProvider theme={theme}>
                      <Autocomplete
                        options={patternList}
                        disabled={disabledPattern}
                        getOptionLabel={(option) => option?.dw_nm}
                        size="small"
                        value={patternName}
                        renderInput={(params) => 
                          <TextField 
                            {...params} 
                            variant="outlined" 
                          />
                        }
                        style={{ width: 150 }}
                        onChange={handleChangePattern}
                      />
                    </ThemeProvider>
                  </td>
                  <td>
                    {" "}
                    {/* <select
                      labelId="length-label"
                      id="dropdown"
                      value={length}
                      disabled={disabledPattern}
                      onChange={(e) => setLength(e.target.value)}
                      className="select-table"
                    >
                      <option value="">None</option>
                      {lengthList.map((item) => (
                        <option value={item.dl_id} key={item.dl_id}>
                          {item.dl_nm}
                        </option>
                      ))}
                    </select> */}
                    <ThemeProvider theme={theme}>
                      <Autocomplete
                        options={lengthList}
                        disabled={disabledPattern}
                        getOptionLabel={(option) => option.dl_nm}
                        size="small"
                        value={lengthName}
                        renderInput={(params) => 
                          <TextField 
                            {...params} 
                            variant="outlined" 
                          />
                        }
                        style={{ width: 150 }}
                        onChange={handleChangeLength}
                      />
                    </ThemeProvider>
                  </td>
                  <td>
                    {/* <input
                      labelId="qunatity-label"
                      id="input"
                      value={quantity}
                      onChange={(e) => setQyantity(e.target.value)}
                      className="select-table"
                      style={{width:'100%'}}
                      placeholder={`${quantity} Bales`}
                    /> */}
                    <TextField
                      variant="outlined"
                      style={{ width: 200 }}
                      size="small"
                      value={quantity}
                      type="number"
                      onChange={(e) => setQyantity(e.target.value)}
                    />
                  </td>
                  <td>{ uomCd }</td>
                  <td>
                    {/* <input
                      labelId="remark-label"
                      id="input"
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                      className="select-table"
                    /> */}
                    <TextField
                      variant="outlined"
                      style={{ width: 100 }}
                      size="small"
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                    />
                  </td>
                  <td>
                    <AddIcon style={{cursor:'pointer'}} onClick={handleAdd} />
                  </td>
                  <td></td>
                </tr>
                {orderDetails.map((row) => (
                  <tr>
                    <td>{row.item}</td>
                    <td>{row.patternName}</td>
                    <td>{row.lengthName}</td>
                    <td>{row.quantity}</td>
                    <td>{row.uomcd}</td>
                    <td>{row.remark}</td>
                    <td>
                      <RemoveIcon style={{cursor:'pointer'}} onClick={()=>removeItem(row.item)}/>
                    </td>
                    <td>
                      {/* <EditIcon fontSize="small" style={{cursor:'pointer'}} onClick={()=>editItem(row.item)}/> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.saveButton}>
            <button onClick={handleSubmit}>Save</button>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
};

export default MainComponent;
