// MainComponent.js
import React, { useEffect, useState } from "react";
import ModalComponent from "../../../Components/ModalComponent";
import axios from "axios";
import "./IndentPageStyle.css";
import { fixDateHandler } from "../../ReceiptIntimation/ReceiptIntimation";
import { RxCross1 } from "react-icons/rx";
import { IoCheckmark } from "react-icons/io5";
import { IoCheckmarkDone } from "react-icons/io5";
import { AiOutlineStop } from "react-icons/ai";
import { FaRegTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import Loader from "../../../Components/Loader/LoaderComponent";
import { useLoading } from "../../../Components/Loader/LoadingContext";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme();

const MainComponent = () => {
  const [data, setData] = useState([]);
  const [years, setYears] = useState([]);
  const [singleYears, setSingleYears] = useState("");
  const [customerName, setCustomerName] = useState([]);
  const [singleCustomerName, setSingleCustomerName] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [fyCd, setFyCd] = useState(null);
  const { isLoading, setIsLoading } = useLoading();

  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    getCustomers();
    getYears();
  }, []);

  const handleShowAll = async (defaultYear) => {
    const { data } = await axios.get(
      "https://aisapi.solzon.in/api/Indent/GetAll",
      {
        params: {
          p_fy_cd: defaultYear.fy_cd,
          p_ac_cd: localStorage.getItem("aup_cd"),
        },
      }
    );
    setData(data.data);
  };

  const getCustomers = async () => {
    try {
      const { data } = await axios.get(
        `https://aisapi.solzon.in/api/Customers/List`,
        {
          params: {
            aup_cd: localStorage.getItem("aup_cd"),
            au_type: localStorage.getItem("au_typ"),
          },
        }
      );
      setCustomerName(data.data);
      setSingleCustomerName(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getYears = async () => {
    try {
      const { data } = await axios.get(
        `https://aisapi.solzon.in/api/FinancialYear/List?au_type=A`
      );
      setYears(data.data);
      setSingleYears(data?.data[0]?.fy_cd);
      setFyCd({ fy_cd: data?.data[0]?.fy_cd });
      handleShowAll(data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const handleViewAllIndent = (actionName, action, event) => {
    setIsLoading(true);
    if (action) {
      action(event);
    }
    // if (actionName === "customer" && event && years) {
    //   queryParms["p_ac_cd"] = event;
    //   queryParms["p_fy_cd"] = singleYears;
    // } else if (actionName === "years" && event && customerName) {
    //   queryParms["p_fy_cd"] = event;
    //   queryParms["p_ac_cd"] = singleCustomerName;
    // }
    axios
      .get(`https://aisapi.solzon.in/api/Indent/GetAll`, {
        params: {
          p_fy_cd: actionName === "years" ? event : singleYears,
          p_ac_cd: actionName === "customer" ? event : singleCustomerName,
        },
      })
      .then((response) => {
        setIsLoading(false);
        setData(response.data.data);
        console.log(response.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const modalOpenHandler = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
    console.log(content);
  };

  const renderStatus = (status) => {
    switch (status) {
      case "U":
        return <FaRegTrashAlt size={20} color="red" />;

      case "A":
        return <IoCheckmark size={20} color="green" />;

      case "F":
        return <IoCheckmarkDone size={20} color="green" />;

      case "R":
        return <FaRegTrashAlt size={20} color="red" />;
      default:
        break;
    }
  };

  const deleteIndentHabndler = async (indent) => {
    console.log(indent);
    try {
      const { data } = await axios.delete(
        "https://aisapi.solzon.in/api/Indent/DeleteIndentItems",
        {
          params: {
            ii_id: indent.ii_id,
          },
        }
      );
      console.log(data);
      setIsModalOpen(false);
      toast.success(data.data);
      handleViewAllIndent();
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1500);
    } catch (error) {
      console.log(error.response);
      toast.error(error.response.data.message);
    }
  };
  // if (isLoading) {
  //   return <Loader />;
  // }

  const handleAutocompleteChange = (event, newValue) => {
    if (newValue) {
      handleViewAllIndent("customer", setSingleCustomerName, newValue.ac_cd);
    } else {
      handleViewAllIndent("customer", setSingleCustomerName, null);
    }
  };

  const handleAutocompleteChangeFinancialYear = (event, newValue) => {
    if (newValue) {
      setFyCd({ fy_cd: newValue.fy_cd });
      handleViewAllIndent("years", setSingleCustomerName, newValue.fy_cd);
    } else {
      handleViewAllIndent("years", setSingleCustomerName, null);
    }
  };

  return (
    <div>
      <ModalComponent isOpen={isModalOpen} onClose={closeModal}>
        <div className="modalStuff">
          <div className="tableContainer">
            <table className="table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Width</th>
                  <th>Length</th>
                  <th>Quantity</th>
                  <th>Remarks</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {modalContent?.map((content) => (
                  <tr>
                    <td>{content.mn_nm}</td>
                    <td>{content.dw_nm}</td>
                    <td>{content.dl_nm}</td>
                    <td>{content.qty}</td>
                    <td>{content.rmrk_itm}</td>
                    <td onClick={() => deleteIndentHabndler(content)}>
                      {renderStatus(content.sts)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </ModalComponent>
      <h3 style={{ margin: "2% 2vw" }}>View Indent</h3>
      <div className="indent-form">
        <div className="indent-form-one">
          {/* <label>Financial Year: </label>
          <select
            onChange={(e) => handleViewAllIndent("years", setSingleYears, e)}
          >
            <option value="">--Financial Year--</option>
            {years.map((item) => (
              <option value={item.fy_cd} key={item}>
                {item.fy_cd}
              </option>
            ))}
          </select> */}
          <ThemeProvider theme={theme}>
            <Autocomplete
              options={years}
              getOptionLabel={(option) => option.fy_cd}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Financial Year"
                />
              )}
              value={fyCd}
              style={{ width: 400 }}
              onChange={handleAutocompleteChangeFinancialYear}
            />
          </ThemeProvider>
        </div>
        <div className="indent-form-one">
          {/* <label>Customer: </label>
          <select
            onChange={(e) =>
              handleViewAllIndent("customer", setSingleCustomerName, e)
            }
          >
            <option value="">--Customer Name--</option>
            {customerName.map((item, index) => (
              <option value={item.ac_cd} key={index}>
                {item.ac_nm}
              </option>
            ))}
          </select> */}
          <ThemeProvider theme={theme}>
            <Autocomplete
              options={customerName}
              getOptionLabel={(option) => option.ac_nm}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Customer Name"
                />
              )}
              style={{ width: 400 }}
              onChange={handleAutocompleteChange}
            />
          </ThemeProvider>
        </div>
      </div>
      <div className="tableContainer">
        <table className="table">
          <thead>
            <tr>
              <th>Indent Number</th>
              <th>Indent Date</th>
              <th>Customer Name</th>
              <th>Order Number</th>
              <th>Order Date</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item) => {
              return (
                <tr onClick={() => modalOpenHandler(item.childIndent)}>
                  <td>{item.in_no}</td>
                  <td>{fixDateHandler(item.in_dt)}</td>
                  <td>{item.ac_nm}</td>
                  <td>{item.ord_no === 0 ? "" : item.ord_no}</td>
                  <td>{item.ord_dt}</td>
                  <td>{item.rmrk}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* <div className="indent-table">
        <TableComponent
          data={data}
          childValueChange={handleValueFromChild}
          searchColumn="ac_nm"
        />
        <ModalComponent isOpen={isModalOpen} onClose={closeModal}>
          <h2>Indent Number</h2>
          <p>Party Number</p>
          <TableModalComponent
            data={data}
            childValueChange={handleValueFromChild}
            searchColumn="ac_nm"
          />
        </ModalComponent>
      </div> */}
    </div>
  );
};

export default MainComponent;
