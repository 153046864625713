import React from "react";
import "./Loader.css";
import FadeLoader from "react-spinners/FadeLoader";

const Loader = () => {
  return (
    <div className="loader">
      <FadeLoader color="#5783db" />
    </div>
  );
};

export default Loader;
