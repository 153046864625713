import React, { useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { MdOutlineMenu } from "react-icons/md";
import { IoIosLogOut } from "react-icons/io";
import MobileNav from "../MobileNav/MobileNav";
import { useNavigate } from "react-router-dom";
import userIcon from "../../assets/userIcon.png";
import UserMenu from "../UserMenu";

const Container = styled.div`
  height: 10vh;
  box-shadow: 0 0 4px 0px #868686a9;
  position: relative;
  width: 100%;
  /* border-bottom: 1px solid black; */
`;

const UserImg = styled.img`
  width: 30px;
  padding: 4px;
  border-radius: 100%;
  &:hover {
    background-color: #5783db;
  }
`;

const NavContent = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2vw;
  position: relative;
`;

const DrawerDiv = styled.div`
  display: flex;
  height: 100%;
  font-size: 30px;
  margin-left: 1vw;
  justify-content: space-between;
  align-items: center;
`;

const MobileHeading = styled.div`
  color: #5783db;
  font-weight: 800;
  cursor: default;
  font-size: 30px;
  letter-spacing: 3px;
`;

const LogoutBtn = styled.button`
  padding: 10px 20px;
  display: flex;
  background-color: #5783db;
  color: #fff;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  /* text-align: center; */
  border-radius: 5px;
  border: none;
  cursor: pointer;
  /* &:hover {
    background-color: #5783db;
    transition: all 0.3s ease-in-out;
    color: #fff;
  } */
`;
const Navbar = ({ collapse, sidebarStatus }) => {
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const Navigate = useNavigate();
  const handleContainerClick = () => {
    if (isTabletOrMobile) {
      setOpenMobileNav(!openMobileNav);
    } else {
      collapse(!sidebarStatus);
    }
  };

  return (
    <Container>
      <DrawerDiv>
        <MdOutlineMenu
          onClick={handleContainerClick}
          cursor={"pointer"}
          color="#5783db"
        />
        {isTabletOrMobile && <MobileHeading> AIS</MobileHeading>}
        <ButtonDiv>
          <div>
            <UserImg onClick={() => setShowMenu(!showMenu)} src={userIcon} />
          </div>
          {showMenu && <UserMenu showMenu={setShowMenu} />}
          {/* <LogoutBtn onClick={() => Navigate("/")}>
            <IoIosLogOut fontSize={"20px"} /> Logout
          </LogoutBtn> */}
        </ButtonDiv>
      </DrawerDiv>
      {openMobileNav && isTabletOrMobile ? (
        <MobileNav setOpenMobileNav={setOpenMobileNav} />
      ) : null}
      {/* <NavContent></NavContent> */}
    </Container>
  );
};

export default Navbar;
