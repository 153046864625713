import React, { useEffect, useState } from "react";
import styles from "./ReceiptInformation.module.css";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { CiEdit } from "react-icons/ci";
import { IoMdAdd } from "react-icons/io";
import { FcCancel } from "react-icons/fc";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../../Components/Loader/LoadingContext";
import Loader from "../../Components/Loader/LoaderComponent";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme();

// const data = [
//   {
//     tranNo: "001",
//     date: "2024-04-2 ",
//     cdDate: "2024-04-24",
//     taxableAmount: 1000,
//     gst: 180,
//     osAmt: 1180,
//     adjAmt: 0,
//     cdAmt: 0,
//     discount: "No discount",
//     remark: "No discount",
//   },
//   {
//     tranNo: "002",
//     date: "2024-04-23",
//     cdDate: "2024-04-24",
//     taxableAmount: 1000,
//     gst: 180,
//     osAmt: 1180,
//     adjAmt: 0,
//     cdAmt: 0,
//     discount: "No discount",
//     remark: "No discount",
//   },
// ];

export const fixDateHandler = (dateField) => {
  if (!dateField) {
    return "";
  }
  const apiDate = dateField.split("T")[0];
  const formattedDate = apiDate.split("-");
  return `${formattedDate[2]}-${formattedDate[1]}-${formattedDate[0]}`;
};

const companyApi = async () => {
  try {
    const { data } = await axios.get(
      "https://aisapi.solzon.in/api/ReceiptIntimation/Companies"
    );
    return data.data;
  } catch (error) {
    throw new Error(error);
  }
};

const bankApi = async (selectedCompany) => {
  try {
    const { data } = await axios.get(
      `https://aisapi.solzon.in/api/ReceiptIntimation/DepositeInBank?${selectedCompany}=string`
    );
    return data.data;
  } catch (error) {
    throw new Error(error);
  }
};

const paymentViaApi = async () => {
  try {
    const { data } = await axios.get(
      "https://aisapi.solzon.in/api/ReceiptIntimation/PaymentMethods"
    );
    return data.data;
  } catch (error) {
    throw new Error(error);
  }
};

const ReceiptIntimation = () => {
  const aup_cd = localStorage.getItem("aup_cd");

  const [editingRow, setEditingRow] = useState(null);
  const [bankData, setBankData] = useState([]);
  const [outstandingData, setOutstandingData] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [yourBankList, setYourBankList] = useState([]);
  const [customer, setCustomer] = useState("");
  const [yourbank, setYourBank] = useState("");
  const [showForm, setShowForm] = useState(true);

  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  // const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedChequeNo, setSelectedChequeNo] = useState("");
  const [selectedTxnDate, setSelectedTxnDate] = useState("");
  const [selectedTxnId, setSelectedTxnId] = useState("");
  const [selectedOnAc, setSelectedOnAc] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [totalAdjusteAmt, setTotalAdjAmt] = useState(0);
  const { isLoading, setIsLoading } = useLoading();
  const [totalCdAmount, setTotalCdAmount] = useState(0);
  const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);

  console.log(setSelectedTxnId, setShowForm);

  const { data: companyData } = useQuery({
    queryKey: ["company"],
    queryFn: companyApi,
  });

  const getOutstandingApi = async (selectedCompanyTemp, selectedCustomer) => {
    try {
      const { data } = await axios.get(
        `https://aisapi.solzon.in/api/ReceiptIntimation/CustomerOutstanding?p_co_cd=${selectedCompanyTemp}&p_ac_cd=${selectedCustomer}`
      );
      setOutstandingData(data.data);
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data: bankRes } = await axios.get(
        `https://aisapi.solzon.in/api/ReceiptIntimation/DepositeInBank`,
        {
          params: {
            p_co_cd: selectedCompany,
          },
        }
      );
      setBankData(bankRes.data);
    };
    fetchData();
  }, [selectedCompany]);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const { data: bankRes } = await axios.get(
        `https://aisapi.solzon.in/api/ReceiptIntimation/Banks`
      );
      setYourBankList(bankRes.data);
      setIsLoading(false);
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { data: customerListRes } = await axios.get(
        `https://aisapi.solzon.in/api/Customers/List?aup_cd=${localStorage.getItem(
          "aup_cd"
        )}&au_type=${localStorage.getItem("au_typ")}` // Need to change this API
      );
      setCustomerList(customerListRes.data);
    };
    fetchData();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const { data: customerListRes } = await axios.get(
  //       "https://aisapi.solzon.in/api/ReceiptIntimation/CustomerOutstanding",
  //       {
  //         params: {
  //           p_co_cd: selectedCompany,
  //           p_ac_cd: customer,
  //         },
  //       }
  //     );
  //   };
  //   fetchData();
  // }, [customer, selectedCompany]);

  const { data: paymentViaData } = useQuery({
    queryKey: ["payment"],
    queryFn: paymentViaApi,
  });

  const handleEditClick = (indexTemp, id) => {
    setEditingRow(indexTemp);
    updateItemObj(id, "", "new");
    const newData = [...outstandingData];
    const index = newData.findIndex((obj) => obj.arap_id === id);
    newData[index]["amt_bal"] = newData[index].amt_bal_old;
  };

  const handleSelectCompnay = (e, value) => {
    if (!value) return setSelectedCompany("");
    setSelectedCompany(value.co_cd);
    bankApi();
    getOutstandingApi(value.co_cd, customer);
  };

  const handleSubmit = async () => {
    // setIsLoading(true)
    console.log(finalAmount);
    console.log(outstandingData);
    if (
      !selectedCompany ||
      !selectedBank ||
      !selectedPaymentMethod ||
      finalAmount < 1
    )
      return toast.error("Mandatory fields are missing.");
    let p_arap_id_s = outstandingData.map((item) => item.index);
    let p_amt_adj_s = outstandingData.map((item) => item.adjustedAmount);
    let p_amt_cd_s = outstandingData.map((item) => item.cdAmount);
    let p_amt_disc_s = outstandingData.map((item) => item.discountAmount);
    let p_rmrk_s = outstandingData.map((item) => item.remark);
    let p_sts_s = outstandingData.map((item) => item.status);
    console.log(p_arap_id_s);
    let data = {
      p_co_cd: selectedCompany,
      p_aup_cd: aup_cd,
      p_ac_cd: customer,
      p_hb_cd: selectedBank,
      p_pm_id: selectedPaymentMethod,
      p_bnk_id: yourbank,
      p_bnk_br: "Branch Name",
      p_chq_no: selectedChequeNo,
      p_chq_dt: selectedTxnDate,
      p_chq_amt: finalAmount,
      p_amt_on_ac: selectedOnAc,
      p_otn: selectedTxnId,
      p_rmrk: "RI 1",
      p_arap_id_s: formateMaker(p_arap_id_s),
      p_amt_adj_s: `${formateMaker(p_amt_adj_s)}|`,
      p_amt_cd_s: `${formateMaker(p_amt_cd_s)}|`,
      p_amt_disc_s: `${formateMaker(p_amt_disc_s)}|`,
      p_sts_s: `${formateMaker(p_sts_s)}|`,
      p_rmrk_s: `${formateMaker(p_rmrk_s)}|`,
    };
    try {
      console.log(data);
      let result = await axios.post(
        "https://aisapi.solzon.in/api/ReceiptIntimation/AddReceiptIntimation",
        data
      );
      toast.success(result.data.data.split(".")[1]);
      setTimeout(() => {
        window.location.reload();
      }, 3200);
    } catch (err) {
      console.log(err.response);
      toast.error("something went wrong.");
    }
  };

  const formateMaker = (arr) => {
    let tempArr = [...arr];
    tempArr = tempArr.filter((element) => element !== undefined);
    tempArr = tempArr.join(",");
    tempArr = tempArr.replaceAll(",", "|");
    console.log(tempArr);
    return tempArr;
  };

  const updateItemObj = (id, newValue, action) => {
    const newData = [...outstandingData];
    const index = newData.findIndex((obj) => obj.arap_id === id);
    newData[index]["index"] = newData[index].arap_id;
    newData[index]["status"] = "1";
    let cdAmtTemp = 0;
    let discount = 0;
    if (
      action === "new" &&
      !newData[index]["cdAmount"] &&
      !newData[index]["cdAmount"]
    ) {
      newData[index]["cdAmount"] = 0;
      newData[index]["discountAmount"] = 0;
    }
    if (action === "cdAmount") {
      cdAmtTemp = Math.round((newData[index].amt_txbl * newValue) / 100);
      newData[index]["cdAmount"] = cdAmtTemp;
      newData[index]["cdAmountPercentage"] = newValue;
      newData[index]["adjustedAmount"] = Math.round(
        newData[index].amt_bal_old -
          newData[index].discountAmount -
          newData[index].cdAmount
      );
    }
    if (action === "cdDiscount") {
      discount = Math.round((newData[index].amt_txbl * newValue) / 100);
      newData[index]["discountAmount"] = Math.round(discount);
      newData[index]["discountPercentage"] = newValue;
      newData[index]["adjustedAmount"] = Math.round(
        newData[index].amt_bal_old - discount - newData[index].cdAmount
      );
    }
    setTotalCdAmount(totalCdAmount + cdAmtTemp);
    setTotalDiscountAmount(totalDiscountAmount + Math.round(discount));
    if (action === "remark") {
      newData[index]["remark"] = newValue;
    }
    if (action === "reset") {
      setTotalCdAmount(totalCdAmount - newData[index]["cdAmount"]);
      setFinalAmount(finalAmount - newData[index]["adjustedAmount"])
      setTotalDiscountAmount(totalDiscountAmount - newData[index]["discountAmount"]);
      newData[index]["cdAmount"] = 0;
      newData[index]["adjustedAmount"] = 0;
      newData[index]["discountAmount"] = 0;
      newData[index]["cdAmountPercentage"] = 0;
      newData[index]["discountPercentage"] = 0;
      newData[index]["remark"] = "";
    }
    setOutstandingData(newData);
  };

  const finalUpdateItem = (id) => {
    setEditingRow(false);
    const newData = [...outstandingData];
    const index = newData.findIndex((obj) => obj.arap_id === id);
    newData[index].amt_bal =
      newData[index].amt_bal_old -
      Math.round(
        newData[index].adjustedAmount +
          newData[index].cdAmount +
          newData[index].discountAmount
      );
    let totalAmt = 0;
    totalAmt = newData.reduce((acc, obj) => {
      if (obj.hasOwnProperty("adjustedAmount")) {
        return acc + obj["adjustedAmount"];
      } else {
        return acc;
      }
    }, 0);
    setTotalAdjAmt(totalAmt);
    totalAmt = totalAmt + Math.round(selectedOnAc);
    setFinalAmount(totalAmt);
    setOutstandingData(newData);
  };

  const handleChangeOnAc = (e) => {
    setSelectedOnAc(e.target.value);
    let totalAmt = 0;
    totalAmt = totalAdjusteAmt + Math.round(e.target.value);
    setFinalAmount(totalAmt);
  };

  const handleChangeCustomer = (e, value) => {
    if (!value) return setCustomer("");
    setCustomer(value.ac_cd);
    getOutstandingApi(selectedCompany, value.ac_cd);
  };

  const handleSelectPaymentVia = (e, value) => {
    if (!value) return setSelectedPaymentMethod("");
    setSelectedPaymentMethod(value.pm_id);
  };

  const handleSelectDepositedBank = (e, value) => {
    if (!value) return setSelectedBank("");
    setSelectedBank(value.hb_cd);
  };

  const handleSelectYourBank = (e, value) => {
    if (!value) return setYourBank("");
    setYourBank(value.bnk_id);
  };

  // const handleDateChange = (e) => {
  //   const inputValue = e.target.value;
  //   setSelectedTxnDate(inputValue)
  //   const formattedDate = formatToDDMMYYYY(inputValue);

  //   if (isValidDate(formattedDate)) {
  //     setDate(formattedDate);
  //     setError('');
  //   } else {
  //     setError('Invalid date format. Use dd-mm-yyyy');
  //   }
  // };

  const Navigate = useNavigate();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <section className={styles.mainContainer}>
      {/* <div className={styles.viewButton}>
        <button onClick={() => Navigate("/receiptintimationpage")}>View</button>
      </div> */}
      {/* <div className={styles.heading}>Receipt Intimation</div> */}
      <div className={styles.formTable}>
        <div
          className={styles.formHeading}
          // onClick={() => setShowForm(!showForm)}
        >
          <h1>Payment Details</h1>
          {/* <p className={styles.showForm}>
            {!showForm ? <FaChevronDown /> : <FaChevronUp />}
          </p> */}
        </div>
        {showForm && (
          <>
            <div className={styles.detailsFormSelect}>
              <div
                className={`${styles.formSelect} ${styles.formSelectCustomer}`}
              >
                <ThemeProvider theme={theme}>
                  <Autocomplete
                    options={customerList}
                    getOptionLabel={(option) => option.ac_nm}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Customer Name"
                        variant="outlined"
                        required
                      />
                    )}
                    onChange={handleChangeCustomer}
                  />
                </ThemeProvider>
              </div>
              <div className={styles.formSelect}>
                <ThemeProvider theme={theme}>
                  <Autocomplete
                    options={companyData}
                    getOptionLabel={(option) => option.co_nm}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Company Name"
                        variant="outlined"
                        required
                      />
                    )}
                    style={{ width: "200%" }}
                    onChange={handleSelectCompnay}
                  />
                </ThemeProvider>
              </div>
              <div className={styles.formSelect}>
                <ThemeProvider theme={theme}>
                  <Autocomplete
                    options={yourBankList}
                    getOptionLabel={(option) => option.bnk_nm}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Customet Bank"
                        variant="outlined"
                        required
                      />
                    )}
                    style={{ width: "200%" }}
                    onChange={handleSelectYourBank}
                  />
                </ThemeProvider>
              </div>
              <div className={styles.formSelect}>
                <TextField
                  label="Branch Name"
                  variant="outlined"
                  style={{ width: "200%" }}
                  size="small"
                  onChange={(e) => setSelectedBank(e.target.value)}
                />
              </div>
              <div className={styles.formSelect}>
                <ThemeProvider theme={theme}>
                  <Autocomplete
                    // value={customer}
                    options={bankData}
                    getOptionLabel={(option) => option.hb_nm}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Deposited Bank"
                        variant="outlined"
                        required
                      />
                    )}
                    style={{ width: "200%" }}
                    onChange={handleSelectDepositedBank}
                  />
                </ThemeProvider>
              </div>
              <div className={styles.formSelect}>
                <ThemeProvider theme={theme}>
                  <Autocomplete
                    // value={customer}
                    options={paymentViaData}
                    getOptionLabel={(option) => option.pm_nm}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Payment Method"
                        variant="outlined"
                        required
                      />
                    )}
                    style={{ width: "200%" }}
                    onChange={handleSelectPaymentVia}
                  />
                </ThemeProvider>
              </div>
              <div className={styles.formSelect}>
                <TextField
                  label="Cheque Number"
                  variant="outlined"
                  style={{ width: "200%" }}
                  size="small"
                  onChange={(e) => setSelectedChequeNo(e.target.value)}
                />
              </div>
              <div className={styles.formSelect}>
                <TextField
                  label="Transction Date"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  style={{ width: "200%" }}
                  type="Date"
                  size="small"
                  required
                  // onChange={handleDateChange}
                  onChange={(e) => setSelectedTxnDate(e.target.value)}
                />
              </div>
              {/* <div className={styles.formSelect}>
                <TextField
                  label="Transaction ID"
                  variant="outlined"
                  style={{ width: '200%'}}
                  size="small"
                  onChange={(e) => setSelectedTxnId(e.target.value)}
                />
              </div> */}
            </div>
            {/* <div className={styles.detailsFormInput}>
              <div className={styles.formInput}>
                <label>Branch:</label>
                <input
                  type="text"
                  onChange={(e) => setSelectedBank(e.target.value)}
                />
                
              </div>
              <div className={styles.formInput}>
                <label>Cheque Number:</label>
                <input
                  type="text"
                  onChange={(e) => setSelectedChequeNo(e.target.value)}
                />
              </div>
              <div className={styles.formInput}>
                <label>Transaction / Cheque Date:</label>
                <input
                  type="date"
                  onChange={(e) => setSelectedTxnDate(e.target.value)}
                />
              </div>
              <div className={styles.formInput}>
                <label>Transaction ID:</label>
                <input
                  type="text"
                  onChange={(e) => setSelectedTxnId(e.target.value)}
                />
              </div>
              <div className={styles.formInput}>
                <label>On A/c:</label>
                <input
                  value={selectedOnAc}
                  type="number"
                  onChange={(e) => handleChangeOnAc(e)}
                />
              </div>
              <div className={styles.formInput}>
                <label>Amount</label>
                <input
                  type="number"
                  value={finalAmount}
                  disabled
                  onChange={(e) => setFinalAmount(Math.round(e.target.value))}
                />
              </div>
            </div> */}
            <hr />
          </>
        )}
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.tableColumnOne}>Tran No.</th>
                <th className={styles.tableColumnOne}>Date</th>
                <th className={styles.tableColumnOne}>CD Date</th>
                <th className={styles.tableColumnTwo}>Txbl Amt</th>
                <th className={styles.tableColumnTwo}>GST Amt</th>
                <th className={styles.tableColumnTwo}>O/S Amt</th>
                <th className={styles.tableColumnTwo}>CD Amt</th>
                <th className={styles.tableColumnTwo}>Disc Amt </th>
                <th className={styles.tableColumnTwo}>Adj Amt</th>
                <th className={styles.tableColumnTwo}>Note</th>
                <th style={{ minWidth: "90px", textAlign: "center" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {outstandingData.map((item, index) => (
                <tr key={index}>
                  {editingRow === index ? (
                    <>
                      <td className={styles.tableColumnOne}>
                        {item.tt_txn_no}
                      </td>
                      <td className={styles.tableColumnOne}>
                        {fixDateHandler(item.txn_dt)}
                      </td>
                      <td className={styles.tableColumnOne}>
                        {fixDateHandler(item.cddd)}
                      </td>
                      <td className={styles.tableColumnTwo}>{item.amt_txbl}</td>
                      <td className={styles.tableColumnTwo}>{item.amt_tx}</td>
                      <td className={styles.tableColumnTwo}>{item.amt_bal}</td>
                      <td className={styles.tableColumnTwo}>
                        <input
                          type="number"
                          placeholder="%"
                          value={item.cdAmountPercentage}
                          onChange={(e) =>
                            updateItemObj(
                              item.arap_id,
                              e.target.value,
                              "cdAmount"
                            )
                          }
                        />
                      </td>
                      <td className={styles.tableColumnTwo}>
                        <input
                          type="number"
                          placeholder="%"
                          value={item.discountPercentage}
                          onChange={(e) =>
                            updateItemObj(
                              item.arap_id,
                              e.target.value,
                              "cdDiscount"
                            )
                          }
                        />
                      </td>
                      <td className={styles.tableColumnTwo}>
                        {item.adjustedAmount}
                      </td>
                      <td className={styles.tableColumnTwo}>
                        <input
                          type="text"
                          value={item.remark}
                          onChange={(e) =>
                            updateItemObj(
                              item.arap_id,
                              e.target.value,
                              "remark"
                            )
                          }
                        />
                      </td>
                      <td
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          minWidth: "75px",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <p onClick={() => finalUpdateItem(item.arap_id)}>
                          <IoMdAdd style={{ cursor: "pointer" }} />
                        </p>
                        <p
                          onClick={() =>
                            updateItemObj(item.arap_id, "", "reset")
                          }
                        >
                          <FcCancel style={{ cursor: "pointer" }} />
                        </p>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className={styles.tableColumnOne}>
                        {item.tt_txn_no}
                      </td>
                      <td className={styles.tableColumnOne}>
                        {fixDateHandler(item.txn_dt)}
                      </td>
                      <td className={styles.tableColumnOne}>
                        {fixDateHandler(item.cddd)}
                      </td>
                      <td className={styles.tableColumnTwo}>{item.amt_txbl}</td>
                      <td className={styles.tableColumnTwo}>{item.amt_tx}</td>
                      <td className={styles.tableColumnTwo}>{item.amt_bal}</td>
                      <td className={styles.tableColumnTwo}>{item.cdAmount}</td>
                      <td className={styles.tableColumnTwo}>
                        {item.discountAmount}
                      </td>
                      <td className={styles.tableColumnTwo}>
                        {item.adjustedAmount}
                      </td>
                      <td className={styles.tableColumnTwo}>{item.remark}</td>
                      <td
                        className={styles.tableColumnTwo}
                        style={{ textAlign: "center" }}
                      >
                        <p onClick={() => handleEditClick(index, item.arap_id)}>
                          <CiEdit style={{ cursor: "pointer" }} />
                        </p>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles.saveButton}>
          <div className={styles.saveButtonText}>
            <TextField
              label="On A/C"
              variant="outlined"
              size="small"
              value={selectedOnAc}
              onChange={(e) => handleChangeOnAc(e)}
            />
          </div>
          <div className={styles.saveButtonText}>
            <TextField
              label="CD Amt"
              variant="outlined"
              size="small"
              value={totalCdAmount}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className={styles.saveButtonText}>
            <TextField
              label="Disc Amt"
              size="small"
              value={totalDiscountAmount}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className={styles.saveButtonText}>
            <TextField
              label="Amount"
              variant="outlined"
              size="small"
              value={finalAmount}
              disabled
              onChange={(e) => setFinalAmount(Math.round(e.target.value))}
            />
          </div>
          <button onClick={handleSubmit}>Save</button>
          <button onClick={() => Navigate("/receiptintimationpage")}>
            View
          </button>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default ReceiptIntimation;
